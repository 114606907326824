<template>
  <div class="app-container">
    <div class="block">
      <div class="avatar-wrapper">
        <van-image
          width="60"
          height="60"
          class="avatar"
          round
          :src="wechat_avatar"
        />
        <div class="nickname">{{ $Base64.decode(wechat_nickname) }}</div>
      </div>
      <van-grid square clickable :column-num="3">
        <!-- <van-grid-item
          text="我的信息"
          icon="user-o"
          @click="$router.push({ name: 'MyInfo' })"
        /> -->
        <van-grid-item
          text="我的订单"
          icon="orders-o"
          @click="$router.push({ name: 'MyOrder' })"
        />
        <van-grid-item
          text="我的地址"
          icon="aim"
          @click="$router.push({ name: 'MyAddress' })"
        />
        <van-grid-item
          text="动态活动"
          icon="newspaper-o"
          @click="$router.push({ name: 'MediaList' })"
        />
        <van-grid-item
          text="我的拥金"
          icon="balance-o"
          @click="$router.push({ name: 'CashList' })"
        />
        <van-grid-item
          text="我的活动"
          icon="description"
          @click="$router.push({ name: 'MyAct' })"
        />
        <!-- v-if=" client_type === 3" -->
      </van-grid>
    </div>
  </div>
</template>
<script>
import Cookies from 'js-cookie'
export default {
  data () {
    return {
      wechat_avatar: Cookies.get('wechat_avatar'),
      wechat_nickname: Cookies.get('wechat_nickname'),
      client_type: Number(Cookies.get('client_type'))

    }
  }
}
</script>
<style lang="scss" scoped>
.block {
  .head-title {
    margin: 0;
    padding: 20px 16px 16px;
    color: rgba(69, 90, 100, 0.6);
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
  }
}
.avatar-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  .nickname {
    padding: 10px;
  }
}
.btn {
  padding: 0 20px;
}
</style>